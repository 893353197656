/* global sketchup:false */
export default {
    methods: {
        fileDownload(choice, data) {
            let url;
            if (choice.mimetype) {
                url = window.URL.createObjectURL(new Blob([data], {type: choice.mimetype}));
            } else {
                url = window.URL.createObjectURL(new Blob([data]));
            }
            const link = document.createElement('a');
            link.href = url;
            if (choice.open_in_browser) {
                link.setAttribute('target', ((typeof sketchup !== "undefined") ? '_self' : '_blank')); // _blank does not work in SketchUp's chromium
            } else {
                link.setAttribute('download', choice.filename);
            }
            document.body.appendChild(link);
            link.click();
        },
    }
};